import {createStore} from "vuex";

const store = createStore({
    state: {
        roleInfo: null,
        MenuCollapse: false,
    },
    mutations: {
        setRoleInfo(state,data){
            state.roleInfo = {...state.roleInfo, ...data};
        },
		// 菜单收起展开
		setMenuCollapse(state, data){
			state.MenuCollapse = typeof data == "undefined"?!state.MenuCollapse:data;
		},
    }
})

export default store;