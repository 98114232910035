import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router/index"
import microApp from '@micro-zoe/micro-app'
import ElementUI from 'element-plus';
import 'element-plus/dist/index.css';
// import './assets/element_cover.scss';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import store from '@/store/index';

microApp.start()

const app = createApp(App);
app.use(router).use(store).use(ElementUI).mount('#app');

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
