<template>
	<div>
	    <router-view></router-view>
	</div>
</template>

<script>
export default {
	created(){
		if (sessionStorage.getItem('store')) {
			this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))));
		}
		//在页面刷新时将vuex里的信息保存到sessionStorage里
		window.addEventListener('beforeunload', () => {
			sessionStorage.setItem('store', JSON.stringify(this.$store.state));
		});
	}
}
</script>

<style scoped src="@/assets/style.css"></style>
<style>
html{height:100%;}
body{min-height:100%; font-size:14px; color:#333333;}
html,body,header,section,footer,div,ul,ol,li,img,a,span,em,del,legend,center,strong,var,fieldset,form,label,dl,dt,dd,cite,input,hr,time,mark,code,figcaption,figure,textarea,h1,h2,h3,h4,h5,h6,p{
	margin:0;
	border:0;
	padding:0;
	font-style:normal;
}
html,body {
	-webkit-touch-callout:none;
	-webkit-text-size-adjust:none;
	-webkit-tap-highlight-color:rgba(0, 0, 0, 0);
	background-color: #fff;
}
input[type="search"]::-webkit-search-cancel-button{-webkit-appearance: none;}
input,button,input:focus,button:focus,textarea,textarea:focus{outline:none; box-sizing:border-box;}
input,textarea{-webkit-appearance:none; appearance:none;}
span{display:inline;}
table {border-collapse: collapse; border-spacing: 0;}
.hide,.hidden{display:none!important;}
.touch-style{background-color:#f0f0f0!important;}
.touch-style1{background-color:#eeeeee!important;}
.touch-style2{background-color:rgba(0,0,0,0.05);}
.hover{ opacity: 0.8; }
a,a:active,a:hover,a:link,a:visited{color:inherit; text-decoration:none;}

.aside .el-menu .el-menu-item,
.aside .el-menu .el-menu-item-group li,
.aside .el-sub-menu__title,
.aside .el-submenu__title {
    height: 40px;
    line-height: 40px;
}
.aside .el-sub-menu__title .wi,
.aside .el-menu-item .wi{color:#7c878e;}
.aside .el-menu-item.is-active,
.aside .el-sub-menu__title:hover,
.aside .el-menu-item:hover {
	background-color: #2d5fff!important;
	color:#ffffff!important;
}
.aside .el-sub-menu__title:hover .wi,
.aside .el-menu-item:hover .wi,
.aside .el-menu-item.is-active .wi{color:#ffffff;}
.aside .el-sub-menu .el-menu-item {padding-left: 52px!important;}

.main .el-input-group__append button.el-button,
.main .el-input-group__append button.el-button:hover{background-color:#409eff; color:#ffffff; border-color:#409eff; border-radius:0 4px 4px 0;}
.main .el-input-group__append button.el-button.is-disabled{background:#a0cfff; border-color:#a0cfff;}
</style>
