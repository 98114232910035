import {createRouter,createWebHistory} from "vue-router"


const routes = [
    {
        path: '/',
        redirect: "/app/xxx/index"
    },
    {
        path: '/app/xxx/getcode',
        name: 'getcode',
        component: ()=>import("../view/getcode.vue"),
    },
    {
        path: "/app/xxx/index",
        name: "index",
        component: ()=>import("../view/index.vue"),
        meta: {keepAlive: true}
    },
    {
        path: '/app/xxx/:role',
        name: 'customer',
        component: ()=>import("../view/customer.vue"),
        children: [
            {
                path: "micro/:page*",
                name: "customer-micro",
                component: ()=>import("../components/micro.vue")
            },
            {
                path: "iframe/:page*",
                name: "customer-iframe",
                component: ()=>import("../components/iframe.vue")
            }
        ]
    },
    
    {
        path: '/*',
        redirect: "/app/xxx/index",
    },
]

const router = createRouter({
    base: '/',
    history: createWebHistory('/'),
    routes
});

export default router;
